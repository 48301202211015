import {
  HostListener,
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { AuthenticationService } from "../../common/authentication.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../common/api.service";
import { DetectLocation } from "src/app/pages/shared/service/detect-location.service";
import { GlobalConstants } from "../../common/global-variables";
import { Location } from "@angular/common";
import { catchError, concatMap, debounceTime, switchMap } from "rxjs/operators";
import { Subject, Subscription, forkJoin, of, iif } from "rxjs";
import { DataChangeService } from "../../common/data-change.service";
import { GiftcardService } from "../../gift-cards/service/giftcard.service";
import { AuthenticationComponent } from "../../common/authentication/authentication.component";
import { DOCUMENT } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { GiftDenominationModalComponent } from "../../gift-cards/gift-denomination-modal/gift-denomination-modal.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { MatDialog } from "@angular/material/dialog";
import { CampaignBanners } from "../../vistara-benefit/interfaces/campaign-banners";

@Component({
  selector: "app-template4-header",
  templateUrl: "header.component.html",
  styleUrls: ["header.component.scss"],
  providers: [ApiService, GlobalConstants],
})
export class HeaderComponent implements OnInit {
  datas: string;
  @Input() data: string[];
  @Input() points: any = "/-";
  @Input() redeemResponse: string[];
  @Input() showProfileDropDown: any = false;
  @Output() hideProfileDropDown = new EventEmitter<any>();
  @Output() rewardPoints = new EventEmitter<any>();
  @Output() categoryChanged = new EventEmitter<string>();
  @Output() allCategories = new EventEmitter<any>();
  @Output() reloadTemplate = new EventEmitter();
  @Output() cartResponse = new EventEmitter<object>();
  @Output() cartApiResponseStatus = new EventEmitter<boolean>();
  @Output() userCardDetails = new EventEmitter<any>();
  @ViewChild(AuthenticationComponent) authC: AuthenticationComponent;
  @ViewChild('modalone') modalone;
  sideMenuOpen = false;
  showAuthentication: any = false;
  selectedCat: any = "";
  isSearchDropdownOpen: any = false;
  merchantList: any = [];
  nearBymerchantList: any = [];
  showNull: any;
  searchInput: string = "";
  deviceInfo = null;
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktopDevice: boolean = false;
  isLoggedIn: boolean = false;
  offerCategories: any = [];
  modalOpen: any = false;
  openMenu: any = false;
  openMenuMob: any = false;
  modalOpenMob: any = false;
  userCards: any = [];
  temp2SearchOpen = false;
  public templateNum: number = 1;
  filteredCategories: any;
  categories: any;
  currentUserCard: any;
  private subject: Subject<string> = new Subject();
  searching: boolean = false;
  searchSubscription: Subscription;
  giftcardList = [];
  membershipList = [];
  searchType = "All";
  hide_nav_item_for_greenInitiative: boolean = false;
  isFirstPrivate = this.authenticationService.isFirstPrivateCard;
  progress = false;
  cartItemsArr: any = [];
  currentUrl: string;
  cartProgress = false;
  @Input() burnRatioData;
  avlPoints;
  modalRef: any;
  private cartItemSubscription: Subscription;
  isDiscountedGc: boolean = false;
  public campaignId: string = '';
  public cardDetailName: string = '';

  @HostListener("window:resize", ["$event"])
  onResize() {
    if (window.innerWidth > 1024) {
      this.isDesktopDevice = true;
      this.isTablet = false;
      this.isMobile = false;
      // this.ngOnInit();
    } else if (window.innerWidth > 768) {
      this.isDesktopDevice = false;
      this.isTablet = true;
      this.isMobile = false;
      // this.ngOnInit();
    } else {
      this.isDesktopDevice = false;
      this.isTablet = false;
      this.isMobile = true;
    }
  }
  constructor(
    private router: Router,
    private _apiService: ApiService,
    private _detectLocation: DetectLocation,
    private authenticationService: AuthenticationService,
    public GlobalConstants: GlobalConstants,
    private location: Location,
    private route: ActivatedRoute,
    private service: DataChangeService,
    private giftcardService: GiftcardService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private el: ElementRef,
    private renderer: Renderer2,
    public matDialog: MatDialog
  ) {
    this.onResize();
    this.isLoggedIn = this.authenticationService.isAuthenticated;
    this.authenticationService.isUserLoggedIn.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
      if(!this.userDetails) {
        this.getUserDetails();
      }
    });
    this.authenticationService.authModalOpenEvent.subscribe((isAuthModalOpen) => {
      isAuthModalOpen && this.login();
    });
    if(this.router.url.slice(1) !== 'offers-on-giftcards' && this.router.url.slice(1) !=='giftcards') {
      this.giftcardService.updateCartDetails([]);
    }
    this.cartItemSubscription = this.giftcardService.cartItems.subscribe((data) => {
      if(this.router.url.slice(1)==='offers-on-giftcards' || data.discount) {
        if(this.isLoggedIn) this.giftcardService.clearCartItems(true).then(() => {
          this.pushToCart(data);
        });
      } else {
        if(this.isLoggedIn) this.giftcardService.clearCartItems(false).then(() => {
          this.pushToCart(data);
        });
      }
    }); 
  }

  checkUrl() {
    if (window.location.href.includes("dining")) return false;

    return true;
  }

  getCards() {
    if (localStorage.getItem("userCards") && (typeof(JSON.parse(localStorage.getItem("userCard"))) == "object")) {
      this.userCards = JSON.parse(localStorage.getItem("userCards"));
      this.userCards.forEach((x) => {
        if (
          this.currentUserCard != "undefined" &&
          this.currentUserCard &&
          this.currentUserCard.id &&
          x.id == this.currentUserCard.id
        ) {
          x["currentCard"] = true;
        } else {
          x["currentCard"] = false;
        }
      });
      console.log(this.userCards);
    } else {
      this._apiService.getCards().subscribe(
        (res: any) => {
          if (
            res &&
            res.data &&
            Array.isArray(res.data) &&
            res.data.length > 0
          ) {
            if (this.GlobalConstants.client === "idfc-bank-gi") {
              this.userCards = res.data.filter((card) => {
                return card.attributes.card_type.toLowerCase() === "debit";
              });
            } else {
              this.userCards = res.data;
            }
            localStorage.setItem("userCards", JSON.stringify(this.userCards));
            this.userCardDetails.emit(this.userCards);
            this.userCards.forEach((x) => {
              if (
                this.currentUserCard != "undefined" &&
                this.currentUserCard &&
                this.currentUserCard.id &&
                x.id == this.currentUserCard.id
              ) {
                x["currentCard"] = true;
              } else {
                x["currentCard"] = false;
              }
            });
          }
        },
        (err) => {}
      );
    }
    if (sessionStorage.getItem("points")) {
      this.points = JSON.parse(sessionStorage.getItem("points"));
      this.avlPoints = this.points;
      this.rewardPoints.emit(this.points);
    } else {
      let params = {};
      if (!localStorage.getItem("urlcode")) {
        params["fetch_points"] = true;
      }
      if (this.GlobalConstants.client !== "idfc-bank-gi") {
        this._apiService.getCardsWithPoints(params).subscribe(
          (res: any) => {
            this.points =
              res.data.attributes.points > 0 ? res.data.attributes.points : 0;
            this.rewardPoints.emit(this.points);
            sessionStorage.setItem("points", JSON.stringify(this.points));
            this.avlPoints = this.points;
          },
          (err) => {
            this._apiService.getCardsWithPoints({}).subscribe(
              (res: any) => {
                this.points = res.data.attributes.points;
                sessionStorage.setItem("points", JSON.stringify(this.points));
                this.avlPoints = this.points;
                this.rewardPoints.emit(this.points);
              },
              (err) => {}
            );
          }
        );
      }
    }
  }

  focusSearchBar() {
    setTimeout(() => {
      document.getElementById("searchInput").focus();
    }, 1000);
  }

  ngOnInit() {
    this.authenticationService.checkForUserTokenExpiry();
    this.authenticationService.isCardVerified.subscribe((isVerified) => {
      if(isVerified) {
        this.isLoggedIn = true;
        this.getUserDetails();
      } else {
        this.isLoggedIn = false;
      }
    })


    if (this.GlobalConstants.client === "idfc-bank-gi") {
      this.hide_nav_item_for_greenInitiative = true;
      this.searchType = "Offers";
    }

    // this.service.data$.subscribe(res => this.points = res)  //read the invoked data or default data
    if (localStorage.getItem("userCard")) {
      let usercard = JSON.parse(localStorage.getItem("userCard"));
      this.currentUserCard = usercard;
    }
    if (localStorage.getItem("currentUser")) {
      this.isLoggedIn = true;
      this.getUserDetails();
      this.getCards();
      // this.getGiftcardsInCart();
      this.avlPoints = JSON.parse(sessionStorage.getItem('points'));
      this.getBurnRatio();
    } else {
      this.isLoggedIn = false;
    }

    this.subject.pipe(debounceTime(300)).subscribe((searchTextValue) => {
      this.populateMerchantList(searchTextValue);
    });
    this._detectLocation.getInternationalGeoLocation(false);
    this.getBurnRatio();
  }

  ngOnDestroy() {
    if(this.cartItemSubscription) {
      this.cartItemSubscription.unsubscribe();
    }
  }

  getGiftcardsInCart() {
    this.giftcardService.getGiftcardsInCart().then((res: any) => {
      this.cartItemsArr = res.data;
      this.cartResponse.emit(this.cartItemsArr);
      this.progress = false;
      this.removeFromCart();
    });
  }

  reloadInit() {
    this.ngOnInit();
    this.reloadTemplate.emit();
  }

  debouncedPopulateMerchantList(searchTextValue: any) {
    this.searching = true;
    this.getSearch();
    // this.subject.next(searchTextValue);
  }

  populateMerchantList(event) {
    this.searchInput = event.target.value;
    var params = { name: event.target.value };
    var merchantDiv = document.getElementById("merchant-list");
    this.isSearchDropdownOpen = true;
    if (event.target.value.length >= 3) {
      this._apiService.getMerchants(params).subscribe(
        (res: any) => {
          this.searching = false;
          this.merchantList = res.data.slice(0, 7);
          this.isSearchDropdownOpen = true;
        },
        (err) => {
          this.searching = false;
        }
      );
    } else {
      this.merchantList = [];
      // this.isSearchDropdownOpen = false;
      this.searching = false;
    }
  }
  backClicked() {
    this.location.back();
  }

  login() {
    if(!this.matDialog.getDialogById("authenticationComponent")) {
      this.matDialog.open(AuthenticationComponent, {panelClass: 'authenticationModal', id: "authenticationComponent"})
    }
  }

  logout() {
    this.authenticationService.deleteToken().subscribe(
      (res) => {
        this.authenticationService.logout();
        this.router.navigate(["/"]);
        window.location.href = "/";
      },
      (error) => {
        this.authenticationService.logout();
        this.router.navigate(["/"]);
        window.location.href = "/";
      }
    );
  }
  navigateCategory(categoryName) {
    this.isSearchDropdownOpen = false;
    this.router.navigate(["offer-list", categoryName]);
  }
  navigateToDetails(mer_id, mer_name) {
    this.isSearchDropdownOpen = false;
    this.router.navigate([
      "offer-details",
      mer_id,
      "all",
      mer_name
        .split(" ")
        .join("-")
        .split(",")
        .join("")
        .split("(")
        .join("")
        .split(")")
        .join("")
        .toLowerCase(),
    ]);
  }

  headerTimeout: any;
  ngOnChanges(change) {
    if (this.data) {
    } else {
      this.modalOpen = false;
      this.headerTimeout = setTimeout(() => {
        if (
          (change.showProfileDropDown &&
            change.showProfileDropDown.previousValue == false) ||
          (change.showProfileDropDown &&
            change.showProfileDropDown.previousValue == null)
        ) {
          this.openMenu = change.showProfileDropDown.currentValue;
        }
        clearTimeout(this.headerTimeout);
      }, 200);
    }
  }

  setCurrentUserCards(card) {
    localStorage.removeItem("eligible_banners");
    localStorage.setItem("userCard", JSON.stringify(card));
    window.location.reload();
  }

  resetShowProfileDropDown() {
    this.hideProfileDropDown.emit(false);
  }

  currentTag: any = "national";
  settag(tag) {
    this.currentTag = tag;
    setTimeout(() => {
      this.isSearchDropdownOpen = true;
    }, 200);
  }

  switchCategory(selectedCategory) {
    this.categoryChanged.emit(selectedCategory);
  }

  acceptRules() {}
  closeTab() {}

  emitGiftres(res) {
    this.cartResponse.emit(res);
  }
  cartApiStatus(res) {
    this.cartApiResponseStatus.emit(res);
  }
  userDetails: any;
  getUserDetails() {
    if(!localStorage.getItem("userDetails")) {
      this._apiService.getUserDetails().subscribe((res) => {
        if (res) {
          localStorage.setItem("userDetails", JSON.stringify(res));
          this.userDetails = res;
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      });
    } else {
      this.userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    }
  }
  toLocal(num) {
    if (num == 0 || num == "/-") {
      return num;
    }
    num = parseInt(num);
    return Number(num).toLocaleString("en-IN");
  }

  getSearch() {
    this.merchantList = [];
    this.giftcardList = [];
    this.membershipList = [];
    if (this.searchSubscription) {
      this.searching = false;
      this.searchSubscription.unsubscribe();
    }
    if (this.searchInput && this.searchInput.length > 2) {
      this.searching = true;
      this.searchSubscription = forkJoin([
        this._apiService.getMerchants({ name: this.searchInput }),
        this.giftcardService.searchGiftcards(this.searchInput),
        this._apiService.getMerchants({
          name: this.searchInput,
          benefit_type: "memberships",
        }),
      ]).subscribe((response: any) => {
        this.merchantList = response[0].data;
        this.giftcardList = response[1].data;
        this.membershipList = response[2].data;
        this.searching = false;
      });
    }
  }

  changeSearchType(type) {
    this.searchType = type;
  }
  
  validateCard(card) {
    this._apiService.switchCard(card.id)
      .pipe(
        catchError((error) => {
          console.error('Error validating card:', error);
          return of(null);
        }),
        switchMap((res) => {
          if (
            res &&
            res.data &&
            res.data.attributes.bin == card.attributes.bin &&
            (res.data.attributes.status == "bin_verified"
            || res.data.attributes.status == "3ds_verified")
          ) {
            let current_user = JSON.parse(localStorage.getItem("currentUser"));
            current_user.token = res.data.attributes.session_token;
            localStorage.setItem("currentUser", JSON.stringify(current_user));
            localStorage.setItem("card_verified", "true");
            localStorage.setItem("userCard", JSON.stringify(res.data));
            sessionStorage.setItem("points", JSON.stringify(res?.data?.attributes?.points || 0));
            this.cardDetailName = res.data?.attributes?.card_detail?.name;
  
            if (this.GlobalConstants.mapCardNameToPath[this.cardDetailName]) {
              return this._apiService.getCampaignBanners()
                .pipe(
                  catchError((error) => {
                    // TODO: add toastr if required
                    console.error('Error fetching campaign banners:', error);
                    return of(null);
                  })
                );
            } else {
              location.reload();
              return of(null);
            }
          } else {
            localStorage.setItem("card_verified", "false");
            return of(null);
          }
        })
      )
      .subscribe((res: CampaignBanners | null) => {
        if (res?.data && res?.data?.length) {
          this.campaignId = res.data?.[0]?.attributes?.campaign_id;
          if (this.campaignId && this.campaignId?.trim() !== '' && this.campaignId === "818e37d3-179a-4f2e-b3e7-0d9756f6b79d" ) {
            this.goToExclusiveRewardsPage(this.cardDetailName);
          } else {
            location.reload();
          }
        } else {
          location.reload();
        }
      }, (error: ErrorEvent) => console.error("Error occurred during card validation:", error));
  }

  public goToExclusiveRewardsPage(cardName: string): void {
    this.router.navigate([this.GlobalConstants.mapCardNameToPath[cardName]({campaignId: this.campaignId})]);
  }

  updatePointsInStorage(points: number) {
    this.points = points;
    this.avlPoints = points;
    this.rewardPoints.emit(points);
    sessionStorage.setItem('points', JSON.stringify(points));
  }

  otherThanNull(arr) {
    return arr.filter(function (el) {
      if (el !== false || el !== null || el !== 0 || el !== "") {
        return el;
      }
    }).length ? false : true;
  }

  async pushToCart(data) {
    if(data.discount) {
      this.isDiscountedGc = true;
    } else if(this.router.url.includes("/offers-on-giftcards")) {
      this.isDiscountedGc = true;
    } else {
      this.isDiscountedGc = false;
    }
    if(this.cartItemsArr.length > 0) {
      console.log(this.cartItemsArr.length, "cartItemsArr");
      for (const el of this.cartItemsArr) {
        if (el.attributes.details.gift_card_id == data.id && el.attributes.details.recipient_type == data.recipient_type) {
          if (!data.denominations.includes(el.attributes.details.denomination)) {
            await this.deleteFromCart(el.id);
          }
        }
      }
    }

    const formData = {
      'category': 'GiftCard',
      'items': []
    }
    data.valueNum.forEach((obj, index) => {
      formData.items.push({
        'gift_card_id': data.id,
        'name': data.name,
        'denomination': data.denominations[index],
        'quantity': obj,
        'recipient_type': data.recipient_type
      })
    });
    this.giftcardService.pushToCart(formData, this.isDiscountedGc).subscribe((res: any) => {
      if (res && res.data) {
        if(!data.discount) {
          this.cartItemsArr = res.data;
        }
      }
      this.giftcardService.closeModalEvent.emit(true);
      if(!data.discount) {
        this.cartResponse.emit(this.cartItemsArr);
        this.giftcardService.updateCartDetails(this.cartItemsArr);
      }
      this.removeFromCart();      
      setTimeout(() => {
        // if(!this.isDiscountedGc) {
        //   this.closeCart();
        //   this.renderer.addClass(this.el.nativeElement.querySelector("#cartNum"), "active");
        //   setTimeout(() => {
        //     this.renderer.removeClass(this.el.nativeElement.querySelector("#cartNum"), "active");
        //   },1000);
        //   this.toastr.toastrConfig.maxOpened = 1;
        //   this.toastr.show("Added to cart", "" , {closeButton: false, toastClass: "ngx-toastr addToCardToast", messageClass: "toastMessage"});
        //   this.toggle();
        // }
        // else 
        this.goToCheckout()
      }, 1000);
    }, error => {
      // this.apiStatus.emit(false);
      this.toastr.error(error?.error?.errors?.length ? error?.error?.errors[0] : 'Something went wrong!');
    });
  }

  goToCheckout() {
    this.modalone?.hide();
    if(this.isDiscountedGc) {
      this.router.navigate(['offers-on-giftcards', 'checkout'], {
        queryParams: {
          discountedGiftcards: true
        },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate(['giftcards', 'checkout']);
    }
  }

  getCountOfItems() {
    let count = 0
    if (this.cartItemsArr && this.cartItemsArr.length) {
      this.cartItemsArr.forEach(element => {
        if (element.attributes.details && element.attributes.quantity) {
          count = count + element.attributes.quantity;
        }
      });
    }
    return count;
  }

  getImageUrl(cartItem) {
    if (this.giftcardService.allGiftcards && this.giftcardService.allGiftcards.length) {
      for (let each of this.giftcardService.allGiftcards) {
        if (each.id == cartItem.attributes.details.gift_card_id) {
          return each.attributes.image_url;
        }
      }
    }
    return '';
  }

  async removeFromCart() {
    if (this.cartItemsArr && this.cartItemsArr.length) {
      for (let each of this.cartItemsArr) {
        if (each.attributes.quantity == 0) {
          await this.deleteFromCart(each.id);
        }
      }
    }
  }

  deleteFromCart(id) {
    return new Promise((resolve) => {
      const deleteData = {
        item_id: id,
      }
      this.giftcardService.deleteFromCart(deleteData, undefined).then((res: any) => {
        if (res && res.data && Array.isArray(res.data)) {
          this.cartItemsArr = res.data;
        }
        // this.apiStatus.emit(true);
        this.cartResponse.emit(this.cartItemsArr);
        this.giftcardService.updateCartDetails(this.cartItemsArr);
        resolve(true);
      });
    });
  }


  clearCart() {
    this.cartProgress = true;
    this.giftcardService.clearCartItems().then((res: any) => {
      if (res && res.status == 'success') {
        this.cartItemsArr = [];
      }
      // this.apiStatus.emit(true);
      this.cartResponse.emit(this.cartItemsArr);
      this.giftcardService.updateCartDetails(this.cartItemsArr);
      this.cartProgress = false;
    });
  }

  increment(gift_card_id, denomination, quantity, recipient_type, name) {
    quantity = parseInt(quantity) + 1;
    if (quantity <= this.getMaxQuantityPerOrder(gift_card_id)) {
      let data = [];
      data.push({
        item_id: gift_card_id,
        item_name: name,
        denomination: denomination,
        quantity: quantity,
        recipient_type: recipient_type
      });
      this.addToCart(data);
    } else {
      this.toastr.error('No of quantity cannot be more than ' + this.getMaxQuantityPerOrder(gift_card_id) + '.');
    }
  }

  decrement(item_id, gift_card_id, denomination, quantity, recipient_type, name) {
    quantity = parseInt(quantity) - 1;
    if (quantity > 0) {
      let data = [];
      data.push({
        item_id: gift_card_id,
        item_name: name,
        denomination: denomination,
        quantity: quantity,
        recipient_type: recipient_type
      });
      this.addToCart(data);
    } else {
      this.deleteFromCart(item_id);
    }
  }

  addToCart(data) {
    const formData = {
      'category': 'GiftCard',
      'items': []
    }
    data.forEach(eachdata => {
      formData.items.push({
        'gift_card_id': eachdata.item_id,
        'name': eachdata.item_name,
        'denomination': eachdata.denomination,
        'quantity': eachdata.quantity,
        'recipient_type': eachdata.recipient_type
      })
    });

    this.giftcardService.pushToCart(formData).subscribe((res: any) => {
      if (res && res.data && Array.isArray(res.data)) {
        this.cartItemsArr = res.data;
      }
      // this.apiStatus.emit(true);
      this.cartResponse.emit(this.cartItemsArr);
      this.giftcardService.updateCartDetails(this.cartItemsArr);
      this.removeFromCart();
    } , (error) => {
      this.toastr.error(error?.error?.errors?.length ? error?.error?.errors[0] : 'Something went wrong!');

    });
  }

  getMaxQuantityPerOrder(id) {
    if (this.giftcardService.allGiftcards && this.giftcardService.allGiftcards.length) {
      for (let each of this.giftcardService.allGiftcards) {
        if (each.id == id) {
          return each.attributes.max_quantity_per_order;
        }
      }
    }
    return 4;
  }

  toggle() {
    this.modalone.show();
  }

  closeCart() {
    this.modalone.hide();
  }

  getTotalCartAmount() {
    let total = 0;
    this.cartItemsArr.forEach((each) => {
      if (each && each.attributes.quantity) {
        total = total + (each.attributes.details.denomination * each.attributes.quantity);
      }
    });
    return total;
  }

  getBurnRatio() {
    this.giftcardService.getBurnRatio().then((res) => {
      this.burnRatioData = res;
      this.progress = false;
    })
  }

  getPointsRequired() {
    if (this.burnRatioData && this.burnRatioData.data) {
      return this.getTotalCartAmount() / this.burnRatioData.data;
    } else {
      return this.getTotalCartAmount() / 0.25;
    }
  }

  getPointsAvailable() {
    return this.avlPoints;
  }

  getValueOfPointsAvl() {
    if (this.burnRatioData && this.burnRatioData.data) {
      return this.avlPoints * this.burnRatioData.data;
    } else {
      return this.avlPoints * 0.25;
    }
  }



}
